import React from 'react'
import { Top, Footer, Meta, Breadcrumbs, Advice } from '@src/share-component'
import Container from '@material-ui/core/Container';
import { useIntl } from "gatsby-plugin-react-intl"
import Loading from '@src/share-component/loading/loading';
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Pedestrian from '@src/asset/pedestrian_detection.png'
import Face from '@src/asset/face.jpg'
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { navigate } from 'gatsby'
import Vision from '@src/asset/camera3.jpg'
import Highway from '@src/asset/highway.jpg'
import Mask from '@src/asset/mask_4.jpg'
import ParkingLot from '@src/asset/parking_lot.jpg'
import Pedestrian5 from '@src/asset/pedestrian_5.jpg'
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import './solutions.css'
const solutions_md = 5

const solutions_sm = 5
const solutions_xs = 10
const solutions_xl = 3
const solutions_lg = 3
const list1 = [1, 2, 3]
const app = [Mask, ParkingLot, Pedestrian5]

const category = 'aivision'
const SolutionsPedestrian = () => {

  const intl = useIntl()
  const onClick = (url) => navigate(`${intl.locale}${url}`)
  const slugs = [
    { url : `/${intl.locale}/`, name : intl.formatMessage({ id : "slug-home"})},
    { url : `/${intl.locale}/solutions/`, name : intl.formatMessage({ id : "slug-solutions"}) },
    { url : `/${intl.locale}/solutions/${category}/`, name : intl.formatMessage({ id : `slug-${category}`}) },
  ]

  return <React.Fragment>
    <Meta
     title = {intl.formatMessage({ id : 'solutions-aivision-title'})}
     description = {intl.formatMessage({ id : 'solutions-aivision-description'})}
     slug = {`/solutions/${category}/`}
     />
    <Loading/>
    <div class = 'page'>
    <Top useColorBar />
    <Container classes = {{ root : "solutions-container" }}>
      < Breadcrumbs slugs = {slugs}/>
    </Container>
    <div class = 'solutions-container-bg'>
      <Container classes = {{ root : "solutions-container" }}>
      <Typography variant = "h4" component = "h1" classes = {{ root : 'solutions-block-title' }}> {intl.formatMessage({ id : 'solutions-aivision-title'})} </Typography>
      <Typography variant = "body1" component = "p" classes = {{ root : "solutions-block-content" }}>{ intl.formatMessage({ id : "solutions-aivision-content"}) }</Typography>
      </Container>
    </div>
    <div class = 'solutions-card-container'>
    <Grid container alignItem = "center" justify = "center" spacing = {5}>
      {app.map((s, i) => <Grid item classes = {{ root : "index-solutions-grid" }} xs = {solutions_xs} sm = {solutions_sm} md = {solutions_md} lg = {solutions_lg} xl = {solutions_xl}>
      <Card elevation = {5} classes = {{ root : "index-solutions-card" }}>
      <CardActionArea>
        <CardMedia
          component = "img"
          alt = {intl.formatMessage({ id : `solutions-${category}-card-${i}-title`})}
          height = "200"
          image = {s}
          title = {intl.formatMessage({ id : `solutions-${category}-card-${i}-title`})}
        />
        <CardContent>
          <Typography align = "left" classes = {{ root : 'index-solutions-title' }} gutterBottom variant = "h6" component = "h3">
            {intl.formatMessage({ id : `solutions-${category}-card-${i}-title`})}
          </Typography>
          <Typography align = "left"  classes = {{root : 'index-solutions-content'}} variant = "body1" color = "textSecondary" component = "h4">
            {intl.formatMessage({ id : `solutions-${category}-card-${i}-content`})}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    </Grid>
    )}
  </Grid>
  </div>
    <div class = 'solutions-block'>
      <img id = 'solutions-aivision-1-bg' src = {Vision} class = "solutions-block-bg" />
      <div class = 'solutions-block2'>
      <Typography variant = "h4" component = "h2" classes = {{ root : 'solutions-block2-title' }}> {intl.formatMessage({ id : 'solutions-aivision-title-1'})} </Typography>
      <Typography variant = "body1" component = "p" classes = {{ root : "solutions-block2-content" }}>
        { intl.formatMessage({ id : "solutions-aivision-content-1-1"}) }
      </Typography>
      <List>
        {
          list1.map((l) => <ListItem>
            <ListItemIcon classes = {{ root : "solutions-list-item" }}></ListItemIcon>
            <Typography variant = "body1" component = "h6" classes = {{ root : 'solutions-list-item-text' }}> {intl.formatMessage({ id : `solutions-aivision-list-1-${l}`})} </Typography>
            {/* <ListItemText component = "h6" classes = {{ root : "solutions-list-item-text" }} primary = "Access control devices, such as access cards and card readers"></ListItemText> */}
          </ListItem>
          )
        }
      </List>
      </div>
    </div>
    <div class = 'solutions-block'>
      <img id = 'solutions-aiviosn-2-bg' src = {Pedestrian} class = "solutions-block-bg" />
      <div class = 'solutions-block2-right'>
      <Typography variant = "h4" component = "h2" classes = {{ root : 'solutions-block2-title' }}> {intl.formatMessage({ id : 'solutions-aivision-title-2'})} </Typography>
      <Typography variant = "body1" component = "p" classes = {{ root : "solutions-block2-content" }}>
        { intl.formatMessage({ id : "solutions-aivision-content-2-1"}) }
      </Typography>
      <List>
        {
          list1.map((l) => <ListItem>
            <ListItemIcon classes = {{ root : "solutions-list-item" }}></ListItemIcon>
            <Typography variant = "body1" component = "h6" classes = {{ root : 'solutions-list-item-text' }}> {intl.formatMessage({ id : `solutions-aivision-list-2-${l}`})} </Typography>
            {/* <ListItemText component = "h6" classes = {{ root : "solutions-list-item-text" }} primary = "Access control devices, such as access cards and card readers"></ListItemText> */}
          </ListItem>
          )
        }
      </List>
      </div>
    </div>

    <div class = 'solutions-block'>
      <img id = 'solutions-aiviosn-2-bg' src = {Face} class = "solutions-block-bg" />
      <div class = 'solutions-block2'>
      <Typography variant = "h4" component = "h2" classes = {{ root : 'solutions-block2-title' }}> {intl.formatMessage({ id : 'solutions-aivision-title-3'})} </Typography>
      <Typography variant = "body1" component = "p" classes = {{ root : "solutions-block2-content" }}>
        { intl.formatMessage({ id : "solutions-aivision-content-2-1"}) }
      </Typography>
      <List>
        {
          list1.map((l) => <ListItem>
            <ListItemIcon classes = {{ root : "solutions-list-item" }}></ListItemIcon>
            <Typography variant = "body1" component = "h6" classes = {{ root : 'solutions-list-item-text' }}> {intl.formatMessage({ id : `solutions-aivision-list-3-${l}`})} </Typography>
            {/* <ListItemText component = "h6" classes = {{ root : "solutions-list-item-text" }} primary = "Access control devices, such as access cards and card readers"></ListItemText> */}
          </ListItem>
          )
        }
      </List>
      </div>
    </div>
    <Advice/>
    <Footer/>
    </div>
    
    </React.Fragment>


}



export default SolutionsPedestrian